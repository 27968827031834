import React, {useState} from 'react'
import '../index.scss'
import SEO from '../components/seo'
import Welcome from '../components/Welcome/Welcome'
import About from '../components/About/About'
import Navbar from '../components/Navbar/navbar/Navbar'
import Menu from '../components/Navbar/menu/Menu'
import Youtube from '../components/Youtube/Youtube'
import Services from '../components/Services/Services'
import Footer from '../components/Footer/Footer'
import Map from '../components/Map/Map'
import Donate from '../components/Donate/Donate'

const IndexPageDE = () => {
    const [menuOpen, setMenu] = useState<boolean>(false)
    const toggle = () => setMenu(!menuOpen)
    const close = () => setMenu(false)

    return <>
        <SEO title="Agape Wien"/>
        <Navbar romana={false} close={close} toggle={toggle}/>
        {menuOpen && <Menu romana={false}/>}
        <Welcome romana={false}/>
        <About romana={false}/>
        <Youtube romana={false}/>
        <Services romana={false}/>
        <Donate romana={false}/>
        <Map/>
        <Footer/>
    </>
}

export default IndexPageDE
